import request from '@/utils/request'

//开启直播
export const openLive = (data) => {
    return request({
        url: '/system/liveRoom/open',
        method: 'post',
        data: data,
        contentType: "application/json;charset=utf-8",
    })
}
//关闭直播
export const closeLive = (params) => {
    return request({
        url: '/system/liveRoom/close',
        method: 'get',
        params,
    })
} 